.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
#sidebar-wrapper{
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

.nav-link {
    display: inline-block;
    height: 20px;
    /*background-color:white;*//*will change background-color of element */
    color: gray; /*will change color of text within the element */
}
    
.nav-link:hover {
    /*background-color:green;*/ /*will change background-color of element on hover */
    color: blue; /*will change color of text within the element on hover */
}


.navbar-nav .nav-item .nav-link {
    color: gray;
  }

  .navbar-nav:hover .nav-item:hover .nav-link:hover {
    color: blue;
  }

  .dropdown-menu .dropdown-item {
    color: gray;
  }

  .dropdown-menu .dropdown-item:hover {
    color: blue;
  }
  