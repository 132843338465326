.privacy_policy li {
    list-style: none;
  }
  .privacy_policy li:after {
    content: "\A";
    white-space: pre;
  }

  .terms li:first-child {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: left;
    color: #555;
    padding: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #ccc;
  }
  ul:not(.browser-default) > li {
    list-style-type: none;
  }
  .terms li {
    padding-bottom: 12px;
    padding-left: 16px;
  }